
import Vue from "vue";

export default Vue.extend({
  name: "home",
  data: () => ({
    cards: [
      // {
      //   title: "Link Bundles",
      //   routeName: "bundles",
      //   flex: 12,
      // },
      {
        title: "Files",
        routeName: "files",
        flex: 6,
      },
      // {
      //   title: "Items",
      //   routeName: "items",
      //   flex: 6,
      // },
    ],
  }),
  methods: {
    goto(routeName: string) {
      this.$router.push({ name: routeName });
    },
  },
});
